.game-flags {

  .main-bg {
    max-width: 456px;
  }

  .game-stage {
    position:relative;

    .position1 {
      position:absolute;
      top:50px;
      left:250px;
      width:50px;
      height:50px;
    }
  }

  .flag-match {
    cursor:pointer;
  }

  .test {
    img {
      width: 50px;
      height: 50px;
    }
  
    .hole {
      width: 50px;
      height: 50px;
      border: 1px solid black;
    }
  
    .container {
      display: inline-block;
    }
  
    .slider {
      padding: 50px;
    }
  }

}

.cls-1{fill:#fff;}
//.cls-2{filter: brightness(0) invert(1);}
.cls-3{fill:red;}