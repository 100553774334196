.game-words {
  width:550px;
  margin:0 auto;
  @include media-breakpoint-down(sm) {
    width:100%;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  table {
    border-collapse: collapse;
  }

  /* Style for grid cells */
  .rf-tgrid {
    width: 8.333333333%;
    font-size: 20px;
    vertical-align: middle;
    text-align: center;
  }

  /* Style for the grid */
  .rf-tablestyle {
    width:100%;
    background-color: $white;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  /* Style for the div containing the grid */
  #rf-searchgamecontainer {
    //float: left;
  
  }




  #rf-tablegrid .rf-armed {
    /*  background: lightcyan;*/
  }

  #rf-tablegrid .rf-highlight {
    background: rgba($blue, 0.8);
  }

  #rf-tablegrid .rf-glowing {
    background: rgba($blue, 0.8);
  }

  #rf-tablegrid .rf-selected {
    background: rgba($yellow, 0.8);
  }

  /* style for words that didn't make it on the grid */
  .rf-pfalse {
    color: #111;
    visibility: hidden;
  }

  .hole {
    width: 50px;
    height: 50px;
    border: 1px solid black;
  }

  .container {
    display: inline-block;
  }

  .slider {
    padding: 50px;
  }
}
