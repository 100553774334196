.game-ingame {
  //height:100vh;
  .hide {
    visibility: hidden;
  }
  .status {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    padding: 15px 0;
    z-index: 0;
    @include media-breakpoint-down(md) {
      padding: 5px 0;
    }

    .left {
      position: absolute;
      left: 20px;
    }

    .right {
      position: absolute;
      right: 020px;
    }

    .headline {
      h3 {
        @include media-breakpoint-down(md) {
          font-size: 15px;
          width: 90px;
          word-wrap: break-word;
        }
      }
    }

    .item {
      position: relative;
      background-color: $white;
      display: inline-block;
      padding: 2px 0 2px 30px;
      width: 120px;
      font-size: 25px;
      font-weight: 700;

      @include media-breakpoint-down(md) {
        padding: 0px 0 0px 15px;
        width: 65px;
        font-size: 18px;
      }

      &:before {
        content: "";
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        z-index: 1;
        left: -20px;
        @include media-breakpoint-down(md) {
        }
      }

      &.item-time {
        &:before {
          background: url("/assets/images/gamestatus-time.svg") no-repeat;
          background-size: 50px 50px;
          height: 50px;
          width: 50px;
          @include media-breakpoint-down(md) {
            background-size: 30px 30px;
            height: 30px;
            width: 30px;
          }
        }
      }

      &.item-score {
        &:before {
          background: url("/assets/images/gamestatus-score.svg") no-repeat;
          background-size: 60px 60px;
          height: 60px;
          width: 60px;
          @include media-breakpoint-down(md) {
            background-size: 35px 35px;
            height: 35px;
            width: 35px;
          }
        }
      }
    }
  }

  // Status bar

  /* Style for the div containing the word list */
  #rf-wordcontainer {
    margin-top: 20px;
    pointer-events: none;

    ul {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      background-color: $grey2;
      padding: 10px;
      border-radius: 20px;
      position: relative;
      width: 100px;
      margin-bottom: 0.5rem;
      transition: background-color 1000ms linear;

      @include media-breakpoint-down(sm) {
        min-width: 18%;
        width: 18%;
        height: 50px;
        margin-right: 5px;
        border-radius: 10px;
      }

      &:before {
        background: url("/assets/images/achivment-locked.svg") no-repeat;
        content: "";
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        z-index: 0;
        background-size: 34px 34px;
        height: 34px;
        width: 34px;
      }

      span {
        font-size: 0.8rem;
        padding-top: 40px;
        display: block;
        visibility: hidden;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
      p {
        margin-bottom: 0;
      }
    }

    li.rf-foundword {
      background: $yellow-gradient;
      opacity: 1;
      &:before {
        background: url("/assets/images/achivment.svg") no-repeat;
        content: "";
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        z-index: 0;
        background-size: 34px 34px;
        height: 34px;
        width: 34px;
        @include media-breakpoint-down(sm) {
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      span {
        visibility: visible;
      }
    }
  }
}

.game-flags #rf-wordcontainer {
  li {
    background: $grey2;
    width: 25%;
    opacity: 0.4;
    @include media-breakpoint-down(sm) {
      width: 45%;
    }
    &:before {
      display: none;
      background: url("/assets/images/achivment.svg") no-repeat;
      content: "";
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      z-index: 0;
      background-size: 34px 34px;
      height: 34px;
      width: 34px;
    }

    span {
      font-size: 0.8rem;
      padding-top: 10px;
      display: block;
      visibility: visible;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}

.page-quiz {
  .game-ingame #rf-wordcontainer {
    li {
      height: auto;
      @include media-breakpoint-down(sm) {
        width:31%;
        max-width:31%;
      }
      span {
        @include media-breakpoint-down(sm) {
          display: block;
        }
      }
    }
    li.rf-foundword {
      &:before {
        @include media-breakpoint-down(sm) {
          top: 10px;
          left: 50%;
          transform: translateX(-50%) ;
        }
      }

    }
  }
}

.page-memory {
  .game-ingame #rf-wordcontainer {
    li {
      width: 23.5%;
      margin: 0.25rem;
      @include media-breakpoint-down(sm) {
        width:31%;
        max-width:31%;
      }

    }
  }
}

.countdown {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  h1 {
    font-size: 200px;
  }
}
