.leaderboard {
  .table-container {
    height: 510px;
    background: linear-gradient(
      360deg,
      #dadada -11.54%,
      rgba(218, 218, 218, 0) 131.43%
    );
    border-radius: 30px;
    @include media-breakpoint-down(md) {
      border-radius:0;
      height:350px;
    }
  }
  table {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    border-color: transparent;
    tbody {
      border-top: 0 !important;
      .table-active {
        background-color: transparent;
        font-weight:700;
      }
    }
  }
  table thead {
    flex: 0 0 auto;
    width: calc(100% - 0.9em);
    padding: 20px 0;
    th {
      padding: 20px 0;
    }
  }
  table tbody {
    flex: 1 1 auto;
    display: block;
    overflow-y: scroll;
  }
  table tbody tr {
    width: 100%;
  }
  table thead,
  table tbody tr {
    display: table;
    table-layout: fixed;
  }
}


.content-grid {
  .item {
    border: 1px solid $yellow;
    height:100%;
  
    .heading {
      background-color: $yellow;
      h2 {
        color: $blue;
        font-size:1.2rem;
      
      }
    }


  }
}