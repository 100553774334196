// Colors


$blue: #005BA2;
$blue2: #0F6EF0;
$red: #ED1C24;
$yellow: #F8EF1D;
$yellow2: #FFC815;
$pinkish: #FB9682;
$white: #fff;
$black: #000;
$grey: #656565;
$grey2: #DADADA;
$yellow-gradient: linear-gradient(0deg, #F8EF1D 0.57%, #FBCA1D 113.99%);
$yellow-towhite: linear-gradient(0deg, #FFFFFF 0.57%, #F8EF1D 113.99%);
$green: green;
// Fonts


$red-light: rgba($red, 0.1);
$blue-light: rgba($blue2, 0.1);
$yellow-light: rgba($yellow2, 0.1);
$pinkish-light: rgba($pinkish, 0.1);


$font-family-sans-serif: "Lidl";
$primary: $yellow;
$secondary: $red;

$headings-font-family: "Lidl";
$headings-color: $blue;

$utilities: (
  "custom-margin-top": (
    property: margin-top,
    class: mt,
    values: (
      0: 0,
      1: 0.4rem,
      2: 0.8rem,
      3: 1.2rem,
      4: 1.6rem,
      5: 2rem,
      6: 5rem,
    ),
  ),
  "opacity": (
    property: opacity,
    values: (
      0: 0,
      25: .25,
      50: .5,
      75: .75,
      90: .90,
      100: 1,
    )
  ),
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
);

$theme-colors: (
  "primary": $yellow,
  "secondary": $red,
  "blue":    $blue,
  "red": $red,
  "yellow": $yellow,
  "blue2": $blue2,
  "yellow2":$yellow2,
  "pinkish":$pinkish,
  "red-light": $red-light,
  "blue-light":$blue-light,
  "yellow-light":$yellow-light,
  "pinkish-light" : $pinkish-light,
  "green": $green,
);







$headings-font-weight: 700;

$btn-focus-box-shadow: 0;
$btn-font-family: $font-family-sans-serif;
$btn-font-size: 1.1rem;
$btn-padding-x: 2rem;
$btn-padding-y: 0.6rem;
$btn-border-width: 3px;

$border-radius: 1.875rem;
$tooltip-max-width: 290px;
$tooltip-bg: $white;
$tooltip-color: $black;
$tooltip-padding-y: 20px;
$tooltip-padding-x: 20px;
$tooltip-opacity: 1;

$input-bg: $white;
$input-border-color: $grey;
$input-border-width: 1px;
$input-focus-border-color: $grey;
$input-color: $black;
//$form-floating-label-opacity: 0.5;
$input-focus-box-shadow: none;
$input-padding-x: 1.5rem;
$input-padding-y: 1rem;
$form-check-input-width: 1.5em;
$form-check-input-border:  3px solid $grey;
$form-check-input-border-radius:  50%;

$enable-negative-margins: true;

//@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "~bootstrap/scss/bootstrap";