html,
body {
  //background-color: $blue;
  background-color: $white;
  overflow-x: hidden;
}

.app {
  height: 100vh;
  > .row {
    @include media-breakpoint-up(md) {
      height: 100%;
    }
  }
}

a {
  color: $blue;
  &:hover {
    color: rgba($blue, 0.8);
  }
}

.wrapper {
  background-color: $white;
}

.btn {
  letter-spacing: 0.1em;
  font-weight: 600;
  text-transform: uppercase;
}

// Color styles

stop {
  transition: 0.5s ease;
}

.page-words {
  h1,
  thead th,
  .table-active {
    color: $red;
  }

  .status {
    background: linear-gradient(327.81deg, #ffffff -52.76%, #ef2a32 119.86%);
  }

  .btn {
    background-color: $red;
    color: $white;
  }

  .sidebar {
    background: $red;
    background: linear-gradient(180deg, $red 0%, rgba(255, 255, 255, 1) 100%);
  }

  .bg-sidebar {
    .gradient {
      stop-color: $red;
    }
  }
}

.page-flags {
  h1,
  thead th,
  .table-active {
    color: $yellow2;
  }

  .status {
    background: linear-gradient(327.81deg, #ffffff -52.76%, $yellow2 119.86%);
  }

  .btn {
    background-color: $yellow2;
    color: $white;
  }

  .sidebar {
    background: $yellow2;
    background: linear-gradient(180deg, $yellow2 0%, rgba(255, 255, 255, 1) 100%);
  }
  .bg-sidebar {
    .gradient {
      stop-color: $yellow2;
    }
  }
}

.page-quiz {

  
  h1,thead th, .table-active {
    color: $blue2;
  }
  

  .status {
    background: linear-gradient(180deg, #0F6EF0 0%, #FFFFFF 100%);
  }
  .btn {
    background-color: $blue2;
    color: $white;
  }

  .sidebar {
    background: $blue2;
    background: linear-gradient(180deg, $blue2 0%, rgba(255, 255, 255, 1) 100%);
  }

  .bg-sidebar {
    .gradient {
      stop-color: $blue2;
    }
  }

}
.page-memory {
  h1,thead th, .table-active {
    color: $pinkish;
  }

  .status {
    background: linear-gradient(180deg, $pinkish 0%, #FFFFFF 100%);
  }

  .btn {
    background-color: $pinkish;
    color: $white;
  }

  .sidebar {
    background: $pinkish;
    background: linear-gradient(180deg, $pinkish 0%, rgba(255, 255, 255, 1) 100%);
  }
  .bg-sidebar {
    .gradient {
      stop-color: $pinkish;
    }
  }
}

.page-picnic {
  .wrapper {
    background: url("/assets/images/bg-confeti.svg") no-repeat, $yellow-towhite;
    background-position: center top;

    .event {
      >div {
        background-color: $blue;
        font-size:25px;
        color:$white;
        font-family: $font-family-sans-serif;
        font-weight:700;
        border-radius:30px;
      }

    }
  }
}

.sidebar {
  width: 390px;
  height: 100%;
  position: fixed;
  transition: 0.5s all ease;
  background-position: center bottom;
  background-repeat: no-repeat;

  @include media-breakpoint-down(md) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    width: 100%;
    background: $yellow;

    .logo {
      height: 65px;
    }
  }

  .logo {
    position: relative;
    z-index: 1;
  }

  .bg-sidebar,
  .bg-sidebar-mobile {
    position: absolute;
    bottom: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 0;
  }
}

.main {
  width: calc(100% - 390px);
  margin-left: 390px;
  padding-bottom:40px;

  > div {
    width: 950px;
    margin: 0 auto;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    margin-left: 0;
  }

  .wrapper {
    @include media-breakpoint-down(md) {
      border-radius: 0 !important;
    }
  }
}

.footer {
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;
  background-color: $grey2;
  padding: 5px 0;


  img {
    height: 40px;
  }
}


.loading {
  position:relative;
  &:after {
    content: ' '; 
    display: block; 
    position: absolute; 
    top: 50%; 
    left: 50%; 
    width:131px;
    height:131px;
    transform: translate(-50%,-50%);
    z-index: 5; 
    background: url("/assets/images/loader.svg") no-repeat;
  }
  >div {
    opacity:0.2;
    pointer-events: none;
  }
}

.loader {
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}


.icon-sound {
  position:fixed;
  bottom:20px;
  right:20px;
  cursor: pointer;
  filter: brightness(0%);
}




//Component Memory




.deck {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  perspective: 1000px;
}

.card {
  position: relative;
  box-sizing: border-box;
  text-align: center;
  text-transform: uppercase;
  transition: 0.3s;
  user-select: none;
  transform-style: preserve-3d;
  margin: 0 0 1% 0;
  width: 25%;
  padding-top: 25%;
  border: 0;
  background-color: transparent;

/*   @include media-breakpoint-down(sm) {
    margin: 0 0 1% 0;
    width: 32.33%;
    padding-top: 33.33%;
  } */
}

:focus {
  outline: 1px hotpink dotted;
}

.card div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transition: .6s;
  display: flex;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;
}
.card p {
  margin: 0;
  padding:0;
}
.card img {
  max-width: 98%;
  max-height: 98%;
  border-radius: 5px;
}
.card .front {
  //background-color: #ccc;
  color: #000;
}
.card .back {

  background: url("/assets/images/memory_default.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 98%;


  cursor: pointer;
  color: #ccc;
}
.card .front, 
.card.flipping,
.card.matched {
  transform: rotateY(180deg);
}
.card.matched .front {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05) inset;
  animation-fill-mode: both;
  opacity: .3;
}

.options {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
}
.options .card {
  opacity: .4;
  filter: grayscale(100%);
}
.options .card.selected {
  opacity: 1;
  filter: none;
}

.errors {
  color: #666;
}

.finish {
  background-position: center;
  position: absolute;
  top: 0; left: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.finish .content {
  width: 70%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  position: relative;
  color: #666;
  z-index: 1;
  border: 1px solid #000;
  box-shadow: inset 0 0 6px 2px rgba(0,0,0,.5);
}

.finish h1 {
  font-size: 1.6em;
}

.finish .content:before {
  content: "";
  position: absolute; 
  top: 0; left: 0;
  right: 0; bottom: 0;
  height: 100%;
  width: 100%;
  background: rgba(255,255,255,.6);
  filter: blur(2px);
  z-index: -1;
}
.finish nav {
  justify-content: center;
  flex-wrap: wrap;
}
.finish nav button, 
.finish nav .button {
  margin: 0 20px 10px;
}

.fadein {
  animation: fadein .3s;
}

@keyframes fadein {
  from { opacity: 0.5; }
  to   { opacity: 1; }
}

/* Responsive */
@media all and (min-width: 480px) {
  .finish nav {
    flex-wrap: initial;
  }
  .finish nav button, 
  .finish nav .button {
    margin: 0 20px;
  }
  .card {

  } 
}
