.towergame {
    text-align:center;

    * {
      margin: 0;
      padding: 0;
    }
    img {
      width: 100%;
    }
  
    #canvas {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
    a {
      text-decoration: none;
    }
    li,
    ol,
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    .hide {
      display: none;
    }
    .clear {
      clear: both;
    }
    .loading {
      background-color: #f05a50;
      height: 100%;
      width: 100%;
    }
    .loading .main {
      width: 60%;
      margin: 0 auto;
      color: #fff;
    }
    .loading .main img {
      width: 60%;
      margin: 1rem auto 0;
    }
    .loading .main .title {
      font-size: 0.3rem;
    }
    .loading .main .text {
      font-size: 0.15rem;
    }
    .loading .main .bar {
      height: 0.12rem;
      width: 100%;
      border: 3px solid #fff;
      border-radius: 0.6rem;
      margin: 0.1rem 0;
    }
    .loading .main .bar .sub {
      height: 0.1rem;
      width: 98%;
      margin: 0.008rem auto 0;
    }
    .loading .main .bar .percent {
      height: 100%;
      width: 0;
      background-color: #fff;
      border-radius: 0.6rem;
    }
    .loading .logo {
      position: absolute;
      bottom: 0.3rem;
      left: 0;
      right: 0;
    }
    .loading .logo img {
      width: 1rem;
    }
    .content {
      height: 100vh;
      margin: 0 auto;
      position: relative;
    }
    .landing .title {
      width: 60%;
    }
    .landing .logo {
      width: 30%;
      position: absolute;
      right: 0.2rem;
      top: 0.2rem;
    }
    .landing .action-2 {
      position: absolute;
      bottom: 0.2rem;
      width: 100%;
    }
    .landing .start {
      width: 65%;
    }
    .slideTop {
      -webkit-animation: st 1s ease-in-out;
      animation: st 1s ease-in-out;
    }
    @-webkit-keyframes st {
      0% {
        transform: translateZ(0);
      }
      100% {
        transform: translate3d(0, -100%, 0);
      }
    }
    @keyframes st {
      0% {
        transform: translateZ(0);
      }
      100% {
        transform: translate3d(0, -100%, 0);
      }
    }
    .slideBottom {
      -webkit-animation: sb 1s ease-in-out;
      animation: sb 1s ease-in-out;
    }
    @-webkit-keyframes sb {
      0% {
        transform: translateZ(0);
      }
      100% {
        transform: translate3d(0, 200%, 0);
      }
    }
    @keyframes sb {
      0% {
        transform: translateZ(0);
      }
      100% {
        transform: translate3d(0, 200%, 0);
      }
    }
    .swing {
      -webkit-animation: sw 2s ease-in-out alternate infinite;
      animation: sw 2s ease-in-out alternate infinite;
    }
    @-webkit-keyframes sw {
      0% {
        transform: rotate(5deg);
        transform-origin: top center;
      }
      100% {
        transform: rotate(-5deg);
        transform-origin: top center;
      }
    }
    @keyframes sw {
      0% {
        transform: rotate(5deg);
        transform-origin: top center;
      }
      100% {
        transform: rotate(-5deg);
        transform-origin: top center;
      }
    }
    .ingame-modal .mask {
      background-color: #000;
      opacity: 0.6;
      position: fixed;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }
    .ingame-modal .ingame-modal-content {
      position: fixed;
      height: 100%;
      width: 90%;
      margin-top: 0.3rem;
      top: 0;
    }
    .ingame-modal .main {
      width: 85%;
      margin: 0 auto;
    }
    .ingame-modal .container {
      position: relative;
    }
    .ingame-modal .bg {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .ingame-modal .modal-main {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin-top: -0.4rem;
    }
    .ingame-modal .over-img {
      width: 80%;
      margin: 0.8rem auto 0;
    }
    .ingame-modal .over-score {
      margin-top: -0.2rem;
      font-size: 0.5rem;
      color: #ff735c;
      text-shadow: -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff,
        2px 2px 0 #fff;
    }
    .ingame-modal .tip {
      font-size: 0.16rem;
      color: #9b724e;
    }
    .ingame-modal .over-button-b {
      width: 70%;
      margin: 0.1rem auto 0;
    }
    .wxShare {
      background: #000;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 11;
      opacity: 0.9;
    }
    .wxShare img {
      width: 50%;
      float: right;
      margin: 10px 10px 0 0;
    }
  
    .font-wenxue {
      font-family: wenxue;
    }
  }
  