.custom-labels .rangeslider-horizontal .rangeslider__handle {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.game-words {
    .finished {
        opacity: 0.3;
        pointer-events: none;
    }
}