
.games {
    .item {

        h2 {
            font-size:1.3rem;
            @include media-breakpoint-down(md) {
                font-size:1.3rem;
            }
        }
        img {
            width:50px;
        }

        .btn {
            font-size: 0.9rem;
        }
        &.words {
            background-color: $red-light;
            h2 {
                color: $red;
            }
        }

        &.flags {
            background-color: $yellow-light;

            h2 {
                color: $yellow2;
            }
        }

        &.quiz {
            background-color: $blue-light;

            h2 {
                color: $blue2;
            }
        }

        &.tower {
            background-color: $pinkish-light;

            h2 {
                color: $pinkish;
            }
        }

        &.piknik {
            background: $yellow-towhite;
            background: url("/assets/images/bg-confeti-games.svg") no-repeat, $yellow-towhite;
            background-position: center top;
        }

        .btn {
            @include media-breakpoint-down(md) {
                font-size:0.9rem;
            }
        }

        &.disabled {
            //pointer-events: none;
            filter: grayscale(50%);
            opacity:0.4;
        }
    }
}

