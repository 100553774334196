@font-face {
  font-family: "Lidl";
  src: url("../fonts/LidlFontPro/LidlFontPro-Book.woff2") format("woff2"),
    url("../fonts/LidlFontPro/LidlFontPro-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lidl";
  src: url("../fonts/LidlFontPro/LidlFontPro-Semibold.woff2") format("woff2"),
    url("../fonts/LidlFontPro/LidlFontPro-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Lidl";
  src: url("../fonts/LidlFontPro/LidlFontPro-Bold.woff2") format("woff2"),
    url("../fonts/LidlFontPro/LidlFontPro-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Lidl";
  src: url("../fonts/LidlFontScriptPro/LidlFontScriptPro.woff2") format("woff2"),
    url("../fonts/LidlFontScriptPro/LidlFontScriptPro.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@import "./variables.scss";

@import "./global.scss";
@import "../../games/home.scss";
@import "../../games/game.scss";
@import "../../games/games.scss";
@import "../../games/intro.scss";
@import "../../games/leaderboard.scss";
@import "../../games/Words/words.scss";
@import "../../games/Flags/flags.scss";
@import "../../games/Quiz/quiz.scss";
@import "../../games/Tower/tower.scss";
